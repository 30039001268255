import { Col, Collapse, Row } from 'antd';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import SectionReport, { SectionReportRef } from './SectionReport';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import roles, { getPermission } from 'configs/roles';

const REPORTS_CODES_LIST = ['summaries', 'evaluation', 'summaryGroups', 'summaryEvents', 'summaryOnlyHaveQrs'];

const ReportsManagement: React.FC<ActiveRoles> = () => {
  const { t } = useTranslation();

  /* Selectors */
  const rolesUser = useAppSelector((state) => state.auth.roles);

  /* Refs */
  const configsTemplateRefs = useRef<Array<SectionReportRef>>([]);

  return (
    <div className="p-reportsManagement">
      <HeaderPage
        fixed
        title={`${t('sidebar.reports')}`}
      />
      <div className="t-mainlayout_wrapper">
        <Row gutter={16}>
          <Col>
            <div className="t-repeatersection">
              <Collapse className="u-mt-32 u-mb-24 t-repeatersection_collapse" defaultActiveKey={[0]}>
                {
                  REPORTS_CODES_LIST.map((code, idx) => {
                    if (code === 'summaries' && !getPermission(rolesUser, roles.REPORT_SUMMARIES)) return null;
                    if (code === 'evaluation' && !getPermission(rolesUser, roles.REPORT_EVALUATION)) return null;
                    if (code === 'summaryGroups' && !getPermission(rolesUser, roles.REPORT_SUMMARY_GROUPS)) return null;
                    if (code === 'summaryEvents' && !getPermission(rolesUser, roles.REPORT_SUMMARY_EVENTS)) return null;
                    if (code === 'summaryQrs' && !getPermission(rolesUser, roles.REPORT_SUMMARY_QRS)) return null;
                    if (code === 'summaryOnlyHaveQrs' && !getPermission(rolesUser, roles.REPORT_SUMMARY_ONLY_HAVE_QRS)) return null;

                    return (
                      <SectionReport
                        key={code}
                        activeKey={idx}
                        ref={(innerRef) => {
                          if (innerRef) {
                            configsTemplateRefs.current[idx] = innerRef;
                          }
                        }}
                        code={code}
                      />
                    );
                  })
                }
              </Collapse>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ReportsManagement;
